/*
 * @Author: 月魂
 * @Date: 2022-09-14 09:39:40
 * @LastEditTime: 2022-09-20 16:35:20
 * @LastEditors: 月魂
 * @Description: util
 * @FilePath: \silk-road\src\utils\util.js
 */
import Router from '../router'
/* 防抖 */
export function debounce(fn, delay) {
  let timer;

  return function() {
      if (timer) clearTimeout(timer);
      
      timer = setTimeout(fn, delay);
  }
}

export function handleBack() {
  if(window.location.href.indexOf('isShare') !== -1) {
    Router.push('/');
  } else {
    Router.go(-1);
  }
}
